import React, { useState } from "react";
import Styles from "./Ai.module.css";
import Layout from "Components/Layouts/Common/common";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import one from "Assets/business_1.png";
import two from "Assets/business_2.png";
import three from "Assets/business_3.png";
import four from "Assets/business_4.png";
import five from "Assets/business_5.png";
import six from "Assets/business_6.png";
import seven from "Assets/business_7.png";
import { useNavigate } from "react-router";

function AI() {
    const Navigate = useNavigate();

    const [Tab, setActiveTab] = useState(1);

    const data = [
        {
            image: one,
            text: "An ignorance of business and consumer insights",
        },
        {
            image: two,
            text: "Uncertainty over the state of the market today or tomorrow",
        },
        {
            image: three,
            text: "An incongruent plan and vision",
        },
        {
            image: four,
            text: "difficulty coming up with new ideas or delivering new capabilities fast",
        },
        {
            image: five,
            text: "Ineffective value realisation and strategy implementation",
        },
        {
            image: six,
            text: "A complacent culture or change weariness",
        },
        {
            image: seven,
            text: "a general lack of corporate agility in terms of personnel, workflow, and technology",
        },
    ];
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <div className={Styles["back-drop"]}></div>
                    <h3>Business Consulting Service</h3>
                    <p>
                        Together we can envision, inspire and transform your
                        business.
                    </p>
                    <button onClick={() => Navigate("/Contact-us")}>
                        Talk to Expert <FaLongArrowAltRight />
                    </button>
                </header>
                <div className={Styles["Section_one"]}>
                    <div className={Styles["head"]}>
                        <h3>
                            <span>Home</span> <RiArrowRightDoubleLine />
                            Business Consulting
                        </h3>
                    </div>
                    <div className={Styles["Section_one_content_wrapper"]}>
                        <div className={Styles["Section_one_content"]}>
                            <h3>
                                Businesses are more vulnerable to upheaval now
                                than ever before, thanks to factors like
                                changing consumer expectations, developing
                                technologies, and more nimble competitors
                                entering markets.
                            </h3>
                            <p>
                                We can help whether you're having trouble with
                                your company's culture, improving internal
                                procedures, or coming up with a fresh plan of
                                action that fits your long-term objectives.
                                {/* <br/> */}
                                Our group of knowledgeable business advisors can
                                assist you navigate the current dynamic market
                                environment and maintain your competitive edge
                                while confronting new obstacles. We offer
                                cutting-edge solutions to maximise productivity
                                when working remotely and reduce downtime.
                                {/*  */}
                                Our services can be tailored to meet your needs,
                                from strategic enhancements to complete
                                overhauls. Our consultants work tirelessly to
                                manage intricate projects, produce excellent
                                results, and collaborate effectively across a
                                range of industries.
                                {/*  */}
                                By combining our knowledge of technology and the
                                industry with our consulting expertise, we aim
                                to promote sustainable transformation and
                                ongoing advancement. Not sure where to begin?
                                Get in touch with us to learn how to confidently
                                traverse the ever changing digital landscape.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={Styles["Section_two"]}>
                    <h3>Overcoming Business Obstacles in Partnership</h3>
                    <p>
                        It takes a thorough understanding of your market to
                        identify problems and opportunities in order to navigate
                        business hurdles successfully. Next, you may create an
                        inspiring and appealing vision, cultivate world-class
                        skills, create an agile culture, and quickly and
                        effectively deliver value.
                    </p>
                    <b>
                        Reach out to us for assistance with your business
                        problems.
                    </b>
                    <div className={Styles["Cards"]}>
                        {data.map((item) => (
                            <div className={Styles["Card"]}>
                                <img src={item.image} alt="" />
                                <p>{item.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={Styles["tabs-container_one"]}>
                    <h3>Our Business Consulting Services</h3>
                    <div className={Styles["tabs-wrapper"]}>
                        <div className={Styles["Tabs"]}>
                            <button
                                onClick={() => setActiveTab(1)}
                                className={Tab == 1 ? Styles["active"] : ""}>
                                Alignment of Strategy and Architecture{" "}
                            </button>
                            <button
                                onClick={() => setActiveTab(2)}
                                className={Tab == 2 ? Styles["active"] : ""}>
                                Workforce Management in Human Capital Management
                            </button>
                            <button
                                onClick={() => setActiveTab(3)}
                                className={Tab == 3 ? Styles["active"] : ""}>
                                Efficiency in Operations{" "}
                            </button>
                            <button
                                onClick={() => setActiveTab(4)}
                                className={Tab == 4 ? Styles["active"] : ""}>
                                Individuals & Transformation{" "}
                            </button>
                        </div>
                        {Tab == 1 && (
                            <div className={Styles["Content_tabs"]}>
                                <p>
                                    A road map is essential for steering your
                                    business in the direction of its intended
                                    destiny.
                                    <br />
                                    <br />
                                    As we construct a compelling vision,
                                    strategy, and overall plan for generating
                                    competitive advantage, we'll assist you in
                                    understanding your business and consumer
                                    insights.
                                </p>
                            </div>
                        )}
                        {Tab == 2 && (
                            <div className={Styles["Content_tabs"]}>
                                {/* <h3>Solutions for Scalable Workforce</h3> */}
                                <p>
                                    We successfully optimise, deploy, and
                                    provide support for HCM systems.
                                    <br />
                                    <br />
                                    We collaborate with you to identify the best
                                    course of action for your company, whether
                                    you are interested in a first-time setup or
                                    are migrating to the cloud.
                                </p>
                            </div>
                        )}
                        {Tab == 3 && (
                            <div className={Styles["Content_tabs"]}>
                                <p>
                                    The business architecture of your company
                                    aids in providing the appropriate
                                    capabilities at the appropriate moment for
                                    your organisation.
                                    <br />
                                    <br />
                                    To make sure you can produce genuinely
                                    sustainable results, we'll examine every
                                    aspect of your company's operations
                                    together—people, procedures, technology, and
                                    KPIs.
                                </p>
                            </div>
                        )}
                        {Tab == 4 && (
                            <div className={Styles["Content_tabs"]}>
                                <p>
                                    We'll support your leaders in enabling and
                                    empowering your people to thrive in their
                                    organisation and culture, whether your
                                    business is going through a big transition
                                    or you just need to fortify your culture.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
