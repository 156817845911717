import React from "react";
import ServiceClasses from "./Service.module.css";
import CustomButton from "Components/Button";
interface Card {
    image: string;
    Content: string;
    heading: string;
}
interface props {
    Service: Card;
}

const ServiceCard: React.FC<props> = ({ Service }) => {
    return (
        <article className={ServiceClasses["card"]}>
            <img
                className={ServiceClasses["card__background"]}
                src={Service.image}
                alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                /* width="700"
        height="700" */
            />
            <div
                className={`${ServiceClasses["card__content"]} ${ServiceClasses[" flow"]}`}>
                <div
                    className={`${ServiceClasses["card__content--container"]} ${ServiceClasses[" flow"]}`}>
                    <h2 className={ServiceClasses["card__title"]}>
                        {Service?.heading}
                    </h2>
                    <p className={ServiceClasses["card__description"]}>
                        {Service?.Content}
                    </p>
                </div>
                {/*     <button className={ServiceClasses["card__button"]}>
    Read more
</button> */}
                {/* <CustomButton
                    Title="Read More"
                    onClick={() => {}}
                    Theme="Dark"
                /> */}
            </div>
        </article>
    );
};

export default ServiceCard;
