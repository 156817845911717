import React from "react";
import Styles from "./Styles.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/manu_02_11zon.jpg";
import Imag2 from "Assets/man_03_11zon.jpg";
import Imag3 from "Assets/manu_04_11zon.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import aboutimg_01 from "Assets/23330.jpg";
import aboutimg_02 from "Assets/About/about-img2.jpg";
import aboutimg_03 from "Assets/About/about-img3.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "We offer expert consulting services to guide your digital transformation journey. Our team analyzes your current operations and develops a tailored strategy that aligns with your business goals. From identifying opportunities for improvement to implementing innovative solutions.",
        heading: " Digital Transformation Consulting",
    },
    {
        image: Imag2,
        Content:
            "Our capabilities include integrating advanced technologies such as cloud computing, artificial intelligence, and automation into your existing systems. We ensure seamless implementation that enhances operational efficiency and scales with your business needs.",
        heading: " Advanced Technology Integration",
    },
    {
        image: Imag3,
        Content:
            "We focus on fostering a culture of innovation within your organization. Our services include designing and developing new products, optimizing processes, and adopting emerging technologies.",
        heading: " Innovation Management",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>Transformation and innovation</h3>
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <img src={aboutimg_01} alt="" />
                    <div className={Styles["section_01_about"]}>
                        <h3></h3>
                        <p>
                            At Digital Natives, we specialize in driving
                            transformation and innovation to propel your
                            business into the future. Our comprehensive suite of
                            capabilities ensures that you harness the full
                            potential of cutting-edge technologies and strategic
                            insights to achieve sustainable growth and
                            competitive advantage.
                        </p>
                    </div>
                </div>
                <Service ServiceData={ServiceData} />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
