import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/retail_01.jpg";
import Imag2 from "Assets/AI/intelligence.jpg";
import Imag3 from "Assets/AI/language.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import etail_01 from "Assets/etail_01.jpg";
import aboutimg_02 from "Assets/retail_02_11zon.jpg";
import aboutimg_03 from "Assets/retail_03_11zon.jpg";
import aboutimg_04 from "Assets/retail_04_11zon.jpg";
import aboutimg_05 from "Assets/retail_08_11zon.jpg";
import aboutimg_06 from "Assets/retail_07_11zon.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "To encourage greater customer engagement, put in place dependable single sources of truth and insight delivery engines.",
        heading: "Data Engineering & Analytics",
    },
    {
        image: aboutimg_03,
        Content:
            "  Gain the flexibility and elastic scalability of cloud-based and cloud-native architectures to quickly adjust to changing client needs.",
        heading: "Retail Cloud Enablement",
    },
    {
        image: aboutimg_02,
        Content:
            "Modify your company's procedures to design customer journeys and experiences that win over new customers.",
        heading: "Customer Experience ",
    },
    {
        image: aboutimg_04,
        Content:
            "Utilise blockchain, analytics, and cutting-edge technology to increase the agility of your retail supply chain and logistics",
        heading: "Supply chain",
    },
    {
        image: aboutimg_06,
        Content:
            "Boost your company's digital foundation, get it ready to grow, and start providing amazing experiences on a regular basis.",
        heading: "Digital backbone",
    },
    {
        image: aboutimg_05,
        Content:
            "Get higher returns on marketing campaigns and promotions by modernising merchandising and running campaign measurements.",
        heading: "Sales and marketing ",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>Retail</h3>
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <img src={etail_01} alt="" />
                    <div className={Styles["section_01_about"]}>
                        <h3>surpass the rapidly changing digital consumer</h3>
                        <p>
                            Until consumer brands demonstrate what is possible,
                            consumers frequently don't realise what they need.
                            Furthermore, the possibilities are endless when
                            using digital. Today, retail innovation that
                            considers all of these options and anticipates
                            seismic changes in customer behaviour is
                            strategically essential. As these changes quicken
                            and physical goods become necessities, we support
                            consumer brands in their efforts to innovate in
                            order to overcome resistance to change, alter the
                            market, and reassess consumer expectations.Fortune
                            100 brands can master change with the help of
                            Maximum Close solutions since it provides them with
                            three essential components: Innovation Potential:
                            Using cutting-edge technology to maintain
                            profitability Flexibility: Capacity to incorporate
                            new platforms and add new features Scalability:
                            Designs that can quickly adapt to the market. Our
                            omnichannel engine unifies all physical and digital
                            touchpoints to create seamless customer journeys,
                            allowing you to deliver superior customer
                            experiences. We also work at the speed of relevance,
                            fostering closer relationships between brands and
                            consumers through personalised customer journeys.
                        </p>
                    </div>
                </div>
                <Service ServiceData={ServiceData} />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
