import React from "react";
import Layout from "Components/Layouts/Common/common";
import LoginClasses from "./login.module.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { NavLink } from "react-router-dom";
import CustomButton from "Components/Button";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";
import StampLogo from "Assets/stamp-logo.png";
type Inputs = {
    MobileNumber: string;
    password: string;
};
function Login() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        console.log(data);
        Navigate("/auth/dashboard");
    };

    return (
        <Layout>
            <div className={LoginClasses["Container"]}>
                <div className={LoginClasses["Container_left"]}>
                    <h3> Hello</h3>
                    <h4>
                        {" "}
                        <TypeAnimation
                            sequence={[
                                "Fortunelife's Member", // Types the string
                                2000, // Waits 1s
                            ]}
                            wrapper="span"
                            cursor={false}
                            repeat={0}
                            style={{ display: "inline-block" }}
                        />
                    </h4>
                    <p>
                        Life is more vibrant with you here. Your return brings a
                        burst of energy and positivity, makes everyday count,
                        and let's continue to inspire and achieve greatness
                        together!
                    </p>
                </div>
                <div className={LoginClasses["Container_right"]}>
                    {/* <img src={StampLogo} alt="StampLogo" /> */}
                    <form>
                        <header>
                            <h3>Welcome Back!</h3>
                            <p>
                                Don't have an account?{" "}
                                <NavLink to={"/Create-account"}>
                                    Create now.
                                </NavLink>
                                <br />
                                <span>It takes less than a minute</span>
                            </p>
                        </header>
                        <div className={LoginClasses["form-control"]}>
                            <input
                                type="number"
                                placeholder="Mobile Number"
                                {...register("MobileNumber", {
                                    required: true,
                                })}
                            />
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <input
                                type="password"
                                placeholder="Password"
                                {...register("password", {
                                    required: true,
                                })}
                            />
                        </div>
                        <CustomButton
                            Title="Login Now"
                            onClick={handleSubmit(onSubmit)}
                            Theme="Dark"
                        />
                    </form>
                    <p>
                        Forgot password?{" "}
                        <NavLink to={"/forgot-password"}>Click here</NavLink>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Login;
