import CommonClasses from "./common.module.css";
import SideNav from "./Sidenav/Index";
import Footer from "../Footer/Footer";
interface Layout {
    children: React.ReactNode;
}

const Common: React.FC<Layout> = ({ children }) => {
    return (
        <div className={CommonClasses["Container"]}>
            <SideNav />
            {children}
            <Footer />
        </div>
    );
};

export default Common;
