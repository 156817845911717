import React from "react";
import FooterClasses from "./Footer.module.css";
import Logo from "Assets/logo.png";
import { FaAnglesRight } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { BiPhoneCall } from "react-icons/bi";
import { TbMail } from "react-icons/tb";
import CustomButton from "Components/Button";
import { useNavigate, NavLink } from "react-router-dom";
import { TiSocialFacebook } from "react-icons/ti";

import { FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";

function Footet() {
    const Navigate = useNavigate();
    return (
        <div className={FooterClasses["wrapper"]}>
            <div className={FooterClasses["Container"]}>
                <div
                    className={`${FooterClasses["about"]} ${FooterClasses["content"]}`}>
                    <img src={Logo} alt="Logo" />
                    <p>
                        we specialize in providing advanced digital solutions
                        tailored to your business needs.
                    </p>
                    <button onClick={() => Navigate("/Contact-us")}>
                        Enquire Now
                    </button>
                </div>
                <div className={FooterClasses["content"]}>
                    <h3>Contact US</h3>
                    {/* <div className={FooterClasses["contact_icon"]}>
                        <MdEmail
                            size={18}
                            color="gray"
                            style={{ marginTop: "0.2rem" }}
                        />
                        <div>
                            <h3>Email</h3>
                            <p>info@business.com</p>
                        </div>
                    </div> */}
                    {/*    <div className={FooterClasses["contact_icon"]}>
                        <FaPhone
                            size={18}
                            color="gray"
                            style={{ marginTop: "0.2rem" }}
                        />
                        <div>
                            <h3>Phone</h3>
                            <p>(+1) 4083389367</p>
                        </div>
                    </div> */}
                    {/*  <div className={FooterClasses["contact_icon"]}>
                        <FaMapMarkerAlt
                            size={18}
                            color="gray"
                            style={{ marginTop: "0.2rem" }}
                        />
                        <div>
                            <h3>Address</h3>
                            <p>
                                123 Business Centre London
                                <br /> SW1A 1AA
                            </p>
                        </div>
                    </div> */}
                    <h4 style={{ marginLeft: "2.8rem" }}>INDIA OFFICE</h4>
                    <p style={{ maxWidth: "350px", fontSize: "0.95rem" }}>
                        <IoLocationOutline size={40} />
                        Kondapur Main Road, <br />
                        Laxmi Cyber City, Whitefields, Kondapur,
                        <br /> Hyderabad, Telangana 500081
                    </p>
                </div>
                {/*    <div className={FooterClasses["content"]}>
                    <h3>Services</h3>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Integrated Wellness
                    </NavLink>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Integrated Holiday
                    </NavLink>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Infrastructure Properties
                    </NavLink>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Integrated Fashions
                    </NavLink>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Agriculture Farmlands{" "}
                    </NavLink>
                    <NavLink to={""}>
                        <span>
                            <FaAnglesRight />
                        </span>{" "}
                        Fortune Life Foundation
                    </NavLink>
                </div> */}
                <div className={FooterClasses["content"]}>
                    <h3></h3>
                    <h3></h3>
                    <h3></h3>
                    {/* <h3></h3> */}
                    <h4 style={{ marginLeft: "2.8rem" }}>USA OFFICE</h4>
                    <p style={{ maxWidth: "350px", fontSize: "0.95rem" }}>
                        <IoLocationOutline size={40} />
                        Pleasanton Office Suite <br />
                        4695 Chabot Drive <br />
                        Suite 200 <br />
                        Pleasanton,California 94588
                    </p>
                    <button
                        style={{ marginLeft: "1REM" }}
                        onClick={() =>
                            window.open(
                                "mailto:support@maximumcloudsolutions.com"
                            )
                        }>
                        <TbMail size={18} /> support@maximumcloudsolutions.com{" "}
                    </button>
                </div>
                <div className={FooterClasses["newsletter"]}>
                    <h1>(+1) 4083389367 </h1>
                    <h3>Join Our Newsletter</h3>
                    <div>
                        <input type="text" placeholder="Enter Email" />
                        <CustomButton
                            Title="Submit"
                            onClick={() => {}}
                            Theme="Dark"
                        />
                    </div>
                    <div className={FooterClasses["social"]}>
                        <TiSocialFacebook />
                        <FaInstagram />
                        <FaTwitter />
                        <FaLinkedinIn />
                    </div>
                </div>
            </div>
            <div className={FooterClasses["copyright"]}>
                <p>© Copyright maximum. All right reserved.</p>
                {/*       <div className={FooterClasses["footer_links"]}>
                    <NavLink to={""}>Privacy Policy</NavLink>
                    <p>|</p>
                    <NavLink to={""}>Terms and Conditions</NavLink>
                    <p>|</p>
                    <NavLink to={""}>Shipping Policy</NavLink>
                    <p>|</p>
                    <NavLink to={""}>Refund & Cancellation Policy</NavLink>
                    <p>|</p>
                    <NavLink to={""}>Legal Disclaimers</NavLink>
                    <p>|</p>
                </div> */}
            </div>
        </div>
    );
}

export default Footet;
