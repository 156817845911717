import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/AI/computer.jpg";
import Imag2 from "Assets/AI/intelligence.jpg";
import Imag3 from "Assets/AI/language.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import Cloud from "Assets/cloud_01.jpg";
import Cards from "./cards";
import aboutimg_01 from "Assets/About/about-img1.jpg";
import aboutimg_02 from "Assets/About/about-img2.jpg";
import aboutimg_03 from "Assets/About/about-img3.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag2,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag3,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>Cloud </h3>
                    {/* <p>Solutions /AI</p> */}
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <img src={Cloud} alt="" />
                    <div className={Styles["section_01_about"]}>
                        <h3>
                            <span>Enhance your cloud experience</span>
                            <br />
                            Getting Things Done with Complete Cloud Services
                        </h3>
                        <p>
                            We combine end-to-end cloud services,
                            industry-specific knowledge, cutting-edge cloud
                            emerging technologies, and worldwide resources to
                            optimise your whole cloud journey. Utilising a blend
                            of personnel, procedures, and technology designed to
                            foster cooperation, communication, and creativity,
                            our team of cloud specialists will assist in
                            executing and innovating like never before.
                            <br />
                            <br />
                            By taking care of the entire ownership of cloud
                            operations, cloud managed services, and cloud
                            consumption optimisation, we offer complete cloud
                            management assistance. Our cloud consulting services
                            assist clients in developing a scalable and
                            effective cloud IT infrastructure using
                            tried-and-true best practices. Our cloud
                            modernisation and cloud native application services
                            greatly benefit customers as well, helping to
                            convert legacy systems into a reliable and adaptable
                            IT infrastructure.
                        </p>
                    </div>
                </div>
                <Cards />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
