import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/AI/computer.jpg";
import Imag2 from "Assets/AI/intelligence.jpg";
import Imag3 from "Assets/AI/language.jpg";
import { FaPhoneAlt } from "react-icons/fa";
// import Offer from "./Offer/Offer";
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                </header>
                {/* <Offer /> */}
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
