import { Routes, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import "./App.css";
import { FaWhatsapp } from "react-icons/fa";

// -----------Routes------------------
import CommonRoutes from "Routes/Common/Routes";

export default function App() {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div className={"whole_wrap"}>
            <Routes>
                <Route path="/*" element={<CommonRoutes />} />
            </Routes>
            <div
                className="whatsapp-icon"
                onClick={() => window.open("https://wa.me/14083389367")}>
                <FaWhatsapp color="white" size={33} />
            </div>
        </div>
    );
}
