import React from "react";
import Layout from "Components/Layouts/Common/common";
import Styles from "./Career.module.css";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

function Career() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>Career</h3>
                </header>
                <p>
                    We Are passionate about innovation ,creativity and
                    excellence .we believe in fostering a collabarative and
                    inclusive work environment where every team member can
                    thrive and contribute to our mission.Explore our current Job
                    openings below and find the perfect opportunity to grow your
                    career with us
                </p>
                <div className={Styles["Cards"]}>
                    <div className={Styles["Card"]}>
                        <h3>
                            <BsFillSuitcaseLgFill />
                            Remote-Full Time
                        </h3>
                        <h1>Jr Frontend Developer</h1>
                        <div className={Styles["tags"]}>
                            <p>
                                {" "}
                                <FaHome />
                                Google
                            </p>
                            <p>
                                <IoLocationSharp />
                                London
                            </p>
                        </div>
                        <button>Apply Now</button>
                    </div>
                    <div className={Styles["Card"]}>
                        <h3>
                            <BsFillSuitcaseLgFill />
                            Remote-Full Time
                        </h3>
                        <h1>Jr Frontend Developer</h1>
                        <div className={Styles["tags"]}>
                            <p>
                                {" "}
                                <FaHome />
                                Google
                            </p>
                            <p>
                                <IoLocationSharp />
                                London
                            </p>
                        </div>
                        <button>Apply Now</button>
                    </div>
                    <div className={Styles["Card"]}>
                        <h3>
                            <BsFillSuitcaseLgFill />
                            Remote-Full Time
                        </h3>
                        <h1>Jr Frontend Developer</h1>
                        <div className={Styles["tags"]}>
                            <p>
                                {" "}
                                <FaHome />
                                Google
                            </p>
                            <p>
                                <IoLocationSharp />
                                London
                            </p>
                        </div>
                        <button>Apply Now</button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Career;
