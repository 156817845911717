import React, { useState, useEffect } from "react";
import ServiceClasses from "./Service.module.css";
import Wellness from "Assets/HomePage/Services/wellness1.jpg";
import fashion from "Assets/HomePage/Services/fashion1.jpg";
import foundation from "Assets/HomePage/Services/foundation1.jpg";
import infraspace from "Assets/HomePage/Services/infraspace1.jpg";
import technology from "Assets/HomePage/Services/technology1.jpg";
import vacation from "Assets/HomePage/Services/vacation1.jpg";
import Card from "./ServiceCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import "swiper/swiper.min.css";
// import "swiper/css";

const Service: React.FC<any> = ({ ServiceData }) => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        handleResize(); // Check on component mount
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={ServiceClasses["Container"]}>
            <h3>Our Capabilities</h3>
            {isMobile ? (
                <div className={ServiceClasses["Carosaul-wrapper"]}>
                    <Carousel
                        autoPlay
                        showArrows={false}
                        showStatus={false}
                        useKeyboardArrows
                        showThumbs={false}>
                        {ServiceData.map((item: any, index: any) => (
                            <Card Service={item} />
                        ))}
                    </Carousel>
                </div>
            ) : (
                <div className={ServiceClasses["Cards"]}>
                    {ServiceData.map((item: any) => (
                        <Card Service={item} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Service;
