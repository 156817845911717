import Homepage from "Pages/Common/Home/Index";
import Login from "Pages/Common/Login/login";
import AI from "Pages/Common/AI/AI";
import DigitalTransformation from "Pages/Common/DigitalTransformation/Integratedwellness";
import Cloud from "Pages/Common/Cloud/AI";
import DataPrivacy from "Pages/Common/Dataprivacy/AI";
import DataAnalytics from "Pages/Common/DataAnalytics/Integratedwellness";
import CustomerExperience from "Pages/Common/CustomerExperience/Integratedwellness";
import Retail from "Pages/Common/Retail/AI";
import Insurance from "Pages/Common/Insurance/Integratedwellness";
import Manufacturing from "Pages/Common/Manufacturing/AI";
import Pharma from "Pages/Common/Pharma/Integratedwellness";
import Natives from "Pages/Common/Digital-natives/Natives";
import Transformation from "Pages/Common/Transformation/Integratedwellness";
import CloudServices from "Pages/Common/CloudServices/Natives";
import WhoweAre from "Pages/Common/Whoweare/Integratedwellness";
import Location from "Pages/Common/location/AI";
import Diversity from "Pages/Common/Diversity/Integratedwellness";
import Newsroom from "Pages/Common/Newsroom/AI";
import Leadership from "Pages/Common/LeaderShip/Integratedwellness";
import CoreValues from "Pages/Common/Corevalues/AI";
import Career from "Pages/Common/Career/Career";
import BusinessCuslting from "Pages/Common/BusinessConsulting/AI";
import ContactUs from "Pages/Common/Contact/Contact";
import Policy from "Pages/Common/Privacy-policy/policy";
export const Config = [
    {
        path: "/",
        element: Homepage,
    },
    {
        path: "/privacy-policy",
        element: Policy,
    },
    {
        path: "/AI",
        element: AI,
    },
    {
        path: "/Contact-us",
        element: ContactUs,
    },
    {
        path: "/cloud",
        element: Cloud,
    },
    {
        path: "/core-values",
        element: CoreValues,
    },
    {
        path: "/retail",
        element: Retail,
    },
    {
        path: "/leadership",
        element: Leadership,
    },
    {
        path: "/pharma-lifie-science",
        element: Pharma,
    },
    {
        path: "/news-room",
        element: Newsroom,
    },
    {
        path: "/insurance",
        element: Insurance,
    },
    {
        path: "/manufacturing-distrubtion",
        element: Manufacturing,
    },
    {
        path: "/customer-experience",
        element: CustomerExperience,
    },
    {
        path: "/data-analytics",
        element: DataAnalytics,
    },
    {
        path: "/data-privacy",
        element: DataPrivacy,
    },
    {
        path: "/digital-transformation",
        element: DigitalTransformation,
    },
    {
        path: "/Digital-natives",
        element: Natives,
    },
    {
        path: "/location",
        element: Location,
    },
    {
        path: "/Business-consulting",
        element: BusinessCuslting,
    },
    {
        path: "/transformation-and-innovation",
        element: Transformation,
    },
    {
        path: "/cloud-services",
        element: CloudServices,
    },
    {
        path: "/who-we-are",
        element: WhoweAre,
    },
    {
        path: "/diversity-equity-inclusion",
        element: Diversity,
    },
    {
        path: "/career",
        element: Career,
    },

    {
        path: "/login",
        element: Login,
    },
];
