import React from "react";
import StatsClasses from "./Stats.module.css";
import bg from "Assets/HomePage/stats/bg.png";
import CountUp from "react-countup";

const Stats: React.FC<any> = ({ Data }) => {
    return (
        <div className={StatsClasses["Container"]}>
            {Data.map((item: any) => (
                <div className={StatsClasses["stat"]}>
                    <div className={StatsClasses["stat-img"]}>
                        <img src={item.icon} alt="stat" />
                    </div>
                    <div className={StatsClasses["stat-content"]}>
                        <h3>
                            + <CountUp end={item.Count} />
                        </h3>
                        <p>{item.Heading}</p>
                    </div>
                </div>
            ))}
            <img className={StatsClasses["bg1"]} src={bg} />
        </div>
    );
};

export default Stats;
