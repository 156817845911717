import React from "react";
import BlogClasses from "./Blogs.module.css";
import { AiFillTag } from "react-icons/ai";
import { IoMdTime } from "react-icons/io";
import { MdOutlineFacebook } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaFacebookMessenger } from "react-icons/fa";

import Blog1 from "Assets/blog-1.jpg";
import Blog2 from "Assets/blog-2.jpg";
import Blog3 from "Assets/blog-3.jpg";
function Blogs() {
    return (
        <div className={BlogClasses["Container"]}>
            <div className={BlogClasses["Cards"]}>
                <div className={BlogClasses["Card"]}>
                    <div className={BlogClasses["img"]}>
                        <img src={Blog1} alt="" />
                        <div className={BlogClasses["tag"]}>
                            <span>
                                <AiFillTag />
                                Nodejs
                            </span>
                            <span>
                                <IoMdTime /> Sept 23,2024
                            </span>
                        </div>
                    </div>
                    <div className={BlogClasses["Card-content"]}>
                        <h3>
                            Exploring the Key Features of Laravel 7 Framework
                        </h3>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </p>
                        <div className={BlogClasses["icon-wrapper"]}>
                            <p>Shares</p>
                            <div className={BlogClasses["icon"]}>
                                <MdOutlineFacebook />
                                <FaTwitter />
                                <FaLinkedin />
                                <IoMail />
                                <FaFacebookMessenger />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={BlogClasses["Card"]}>
                    <div className={BlogClasses["img"]}>
                        <img src={Blog2} alt="" />
                        <div
                            className={`${BlogClasses["tag"]} ${BlogClasses["tag-2"]}`}>
                            <span>
                                <AiFillTag />
                                Nodejs
                            </span>
                            <span>
                                <IoMdTime /> Sept 23,2024
                            </span>
                        </div>
                    </div>
                    <div className={BlogClasses["Card-content"]}>
                        <h3>Everything You Need To Know About Nodejs!</h3>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </p>
                        <div className={BlogClasses["icon-wrapper"]}>
                            <p>Shares</p>
                            <div className={BlogClasses["icon"]}>
                                <MdOutlineFacebook />
                                <FaTwitter />
                                <FaLinkedin />
                                <IoMail />
                                <FaFacebookMessenger />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={BlogClasses["Card"]}>
                    <div className={BlogClasses["img"]}>
                        <img src={Blog3} alt="" />
                        <div className={BlogClasses["tag"]}>
                            <span>
                                <AiFillTag />
                                Nodejs
                            </span>
                            <span>
                                <IoMdTime /> Sept 23,2024
                            </span>
                        </div>
                    </div>
                    <div className={BlogClasses["Card-content"]}>
                        <h3>Everything You Need To Know About Nodejs!</h3>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                        </p>
                        <div className={BlogClasses["icon-wrapper"]}>
                            <p>Shares</p>
                            <div className={BlogClasses["icon"]}>
                                <MdOutlineFacebook />
                                <FaTwitter />
                                <FaLinkedin />
                                <IoMail />
                                <FaFacebookMessenger />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;
