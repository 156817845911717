import React from "react";
import { Route, Routes } from "react-router";
import { Config } from "./Config";

export default function Router() {
    return (
        <>
            <Routes>
                {Config.map((route) => (
                    <Route
                        path={route.path}
                        element={<route.element />}></Route>
                ))}
            </Routes>
        </>
    );
}
