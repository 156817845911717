import IntegratedStyles from "./Styles.module.css";
import Layout from "Components/Layouts/Common/common";

import about from "Assets/About/people.jpg";
import Intellectual from "Assets/About/intellectual12.png";
import Yoga from "Assets/About/yoga_01.jpg";
import Social from "Assets/About/social.jpg";
import Product_1 from "Assets/About/product_1.jpeg";
import Product_2 from "Assets/About/product_2.jpeg";
import Product_3 from "Assets/About/product_3.jpeg";
import Product_4 from "Assets/About/product_4.jpeg";
import Product_5 from "Assets/About/product_5.jpeg";
import Product_6 from "Assets/About/product_6.jpeg";
import Hexgon from "Assets/HomePage/banner/hexagon.png";

function Integratedwellness() {
    return (
        <Layout>
            <div className={IntegratedStyles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={IntegratedStyles["hexagon"]}
                    />
                    <h3>DIGITAL TRANSFORMATION</h3>

                    <p>Solutions /Digital Transformation</p>
                </header>
                <section className={IntegratedStyles["section_1"]}>
                    <article>
                        <h3>
                            <span>What is Meaning of</span>
                            <br />
                            Integrated Wellness
                        </h3>
                        <p>
                            The Wellness division of Fortuelife is dedicated to
                            the development of products aimed at enhancing
                            individuals' health and vitality. We firmly believe
                            in the synergistic potential of nature and
                            scientific advancements, and our product range
                            serves as tangible evidence of this conviction.
                        </p>
                        <br />
                        <p>
                            Our diligent research team tirelessly endeavors to
                            ensure that our offerings are not only
                            health-promoting but also safe and efficacious.
                        </p>
                    </article>
                    <img src={Yoga} alt="" />
                </section>
                <section className={IntegratedStyles["section_2"]}>
                    <header>
                        <h3>
                            <span>Dimensions</span> <br /> of Wellness
                        </h3>
                    </header>
                    <div className={IntegratedStyles["card"]}>
                        <img src={Intellectual} alt="Intellectual" />
                        <div className={IntegratedStyles["Content"]}>
                            <h3>Intellectual Wellness</h3>
                            <p>
                                Intellectual wellness involves engaging in
                                creative and stimulating activities to expand
                                your knowledge and skills. It is about
                                maintaining an open mind to new ideas and
                                experiences, which can help you grow personally
                                and professionally. Intellectual wellness can
                                include activities such as reading, solving
                                puzzles, exploring new hobbies, and engaging in
                                discussions. Cultivating intellectual wellness
                                encourages lifelong learning, critical thinking,
                                and the pursuit of knowledge. This, in turn, can
                                enhance your ability to solve problems, make
                                decisions, and navigate life's challenges with
                                confidence and creativity.
                            </p>
                            <ul>
                                <li>Technological Integration</li>
                                <li>Technological Integration</li>
                                <li>Technological Integration</li>
                                <li>Technological Integration</li>
                            </ul>
                        </div>
                    </div>
                    <div className={IntegratedStyles["card"]}>
                        <div className={IntegratedStyles["Content"]}>
                            <h3>Social Wellness</h3>
                            <p>
                                Social wellness is about building and
                                maintaining healthy relationships. It involves
                                good communication, empathy, and the ability to
                                connect with others. Strong social ties with
                                friends, family, and community are key. Engaging
                                in social activities and giving back to your
                                community enhance social wellness. Balancing
                                social time with personal time is essential.
                                Cultivating social wellness leads to a more
                                connected and fulfilling life
                            </p>
                        </div>
                        <img src={Social} alt="Intellectual" />
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default Integratedwellness;
