import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/AI/computer.jpg";
import Imag2 from "Assets/AI/aws.jpg";
import Imag3 from "Assets/AI/azure.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import aboutimg_01 from "Assets/AI_3.jpg";
import aboutimg_02 from "Assets/AI_1.jpg";
import aboutimg_03 from "Assets/AI_5.jpg";

const ServiceData = [
    {
        image: Imag1,
        Content:
            "Snowflake's AI integration enables advanced data analysis and predictive insights directly within the platform. This fusion enhances data processing efficiency and decision-making.",
        heading: "Snowflake",
    },
    {
        image: Imag2,
        Content:
            "AI in AWS powers scalable machine learning models and advanced analytics. It enhances automation, personalizes customer experiences, and drives data-driven decision-making.",
        heading: "AWS",
    },
    {
        image: Imag3,
        Content:
            "We use Azure AI to streamline model development and deployment, enabling advanced analytics and automation. It enhances decision-making and operational efficiency across various applications.",
        heading: "AZURE",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>AI / ML</h3>
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <section className={Styles["section_01"]}>
                        <div className={Styles["section_01_row"]}>
                            <img src={aboutimg_01} alt="image_1" />
                            <img src={aboutimg_02} alt="image_2" />
                        </div>
                        <div className={Styles["section_01_row"]}>
                            <p className={Styles["section_01_content"]}>
                                Years of Experience - <br />
                                Economical Solution
                            </p>
                            <img
                                src={aboutimg_03}
                                alt="image_2"
                                style={{ width: "16rem" }}
                            />
                        </div>
                    </section>
                    <div className={Styles["section_01_about"]}>
                        <h3>
                            <span>Welcome To AI</span>
                            <br />
                            Boost your business with <br /> cutting-edge AI
                            solutions.
                        </h3>
                        <p>
                            Companies today are turning to artificial
                            intelligence to enhance their decision-making
                            capabilities and gain deeper insights from extensive
                            data collections. This strategic approach not only
                            bolsters their resilience against disruptions but
                            also streamlines operations. With AI, Organizations
                            can automate various tasks with remarkable
                            precision, leading to fewer mistakes, increased
                            efficiency, and quicker, more informed choices.{" "}
                            <br />
                            <br />
                            Our team specializes in creating advanced AI
                            solutions that incorporate a range of cutting-edge
                            technologies, including computer vision, generative
                            AI, natural language processing, deep learning,
                            reinforcement learning, IoT, digital twins, and
                            predictive analytics. These innovative solutions
                            empower businesses to implement full-spectrum
                            automation across all operational areas.
                        </p>
                    </div>
                </div>
                <Service ServiceData={ServiceData} />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt />{" "}
                        <span>(+1) 4083389367</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
