import React from "react";
import Layout from "Components/Layouts/Common/common";
import Styles from "./Styles.module.css";
import Staff_1 from "Assets/staff_1.svg";
import Staff_2 from "Assets/staff_2.svg";
import Staff_3 from "Assets/staff_3.svg";
import Staff_4 from "Assets/staff_4.svg";
import Staff_5 from "Assets/staff_5.svg";
import Staff_6 from "Assets/staff_6.svg";
import img1 from "Assets/HomePage/staff_1.svg";
import img2 from "Assets/HomePage/staff_2.svg";
import img3 from "Assets/HomePage/staff_3.svg";
import img4 from "Assets/HomePage/staff_4.svg";
import { FaLongArrowAltRight } from "react-icons/fa";
import Earning from "Components/Home/Earning/earning";

import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { HiClipboardList } from "react-icons/hi";
import { SiCashapp } from "react-icons/si";
import { TbCopy } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
function Integratedwellness() {
    const Navigate = useNavigate();
    let Cards = [
        {
            Title: "Flexible Placement",
            Content:
                "We oversee all aspects of the flexible placement hiring process, including payroll processing, administration, and candidate searching.",
            Icon: <BsFillSuitcaseLgFill color="#141763" />,
            img: img1,
        },
        {
            Title: "Permanent Recruitment",
            img: img2,
            Content:
                "We ensure the ideal fit throughout the entire process, from sourcing to shortlisting to targeted talent selection and large-scale hiring.",
            Icon: <HiClipboardList color="#141763" />,
        },
        {
            img: img3,
            Title: "Diversity & Inclusion",
            Content:
                "Make use of our knowledge to locate the most competent and varied talent available.",
            Icon: <SiCashapp color="#141763" />,
        },
        {
            img: img4,
            Title: "Training",
            Content:
                "Put your staff's skills to use by retraining, upskilling, and training them.",
            Icon: <TbCopy color="#141763" />,
        },
    ];
    const Data = [
        {
            image: Staff_1,
            heading: "Look for employment",
            text: "Look through hundreds of job kinds and sectors to find thousands of local opportunities.",
        },
        {
            image: Staff_2,
            heading: "Complement your abilities",
            text: "Examine the job description to determine whether your qualifications and background align.",
        },
        {
            image: Staff_3,
            heading: "Click “apply”",
            text: "Apply to as many jobs as you like",
        },
        {
            image: Staff_4,
            heading: "Verify your mailbox.",
            text: "Browse thousands of local positions in a wide range of sectors and job kinds.",
        },
        {
            image: Staff_5,
            heading: "Evaluate and conduct interviews",
            text: "Look through hundreds of industries and thousands of local jobs.",
        },
        {
            image: Staff_6,
            heading: "Acquire employment",
            text: "Search through thousands of local jobs across hundreds of job types and industries.",
        },
    ];
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <div className={Styles["back-drop"]}></div>
                    <h3>Staffing</h3>
                    <p>
                        Connecting you with top-tier talent for seamless,
                        effective staffing solutions.
                    </p>
                    <button onClick={() => Navigate("/Contact-us")}>
                        Talk to Expert <FaLongArrowAltRight />
                    </button>
                </header>
                <div className={Styles["Section_one"]}>
                    <h3>Greater than a simple employment firm.</h3>
                    <p>
                        We are here to support you as you advance in your
                        career, regardless of whether you are employed
                        permanently or temporarily as one of our associates.
                    </p>
                    <p>
                        {" "}
                        We can assist you in obtaining the training and
                        upskilling you require to be prepared for the future. We
                        have thousands of jobs in your region that require a
                        variety of skill sets.
                    </p>
                    <p>
                        Maximum cloud solutions is here to support and guide you
                        in your future steps, regardless of how long you've been
                        in the industry for or how recently you started.
                    </p>
                </div>
                <div className={Styles["Section_two"]}>
                    <h3>How our application process works</h3>
                    <p>
                        We remove all uncertainty from locating your ideal
                        partner.
                    </p>
                </div>
                <div className={Styles["Cards_1"]}>
                    {Data.map((item) => (
                        <div className={Styles["Card_1"]}>
                            <img src={item.image} alt="" />
                            <div>
                                <h3>{item.heading}</h3>
                                <p>{item?.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={Styles["Section_one"]}>
                    <h3>Employment Solutions</h3>
                    <p>
                        Use our specialised recruitment solutions to realise the
                        full potential of your company. Find out more about our
                        entire range of services for businesses of all sizes in
                        every sector.
                    </p>
                </div>
                <div className={Styles["Cards"]}>
                    {Cards.map((item) => (
                        <div className={Styles["Card"]}>
                            <div>
                                <img src={item?.img} alt="" />
                            </div>
                            <h3>{item.Title}</h3>
                            <p>{item.Content}</p>
                            {/*     <button>
                                Read More <FaArrowRight color="#191d88" />
                            </button> */}
                        </div>
                    ))}
                </div>
            </div>
            <Earning />
        </Layout>
    );
}

export default Integratedwellness;
