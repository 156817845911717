import IntegratedStyles from "./Styles.module.css";
import Layout from "Components/Layouts/Common/common";
import { useState } from "react";
import about from "Assets/About/people.jpg";
import Intellectual from "Assets/About/intellectual12.png";
import Yoga from "Assets/About/yoga_01.jpg";
import staff5 from "Assets/staffing_5.jpg";
import staffing_1 from "Assets/native_1_11zon.jpg";
import staffing_3 from "Assets/our mission_11zon.jpg";
import staffing_4 from "Assets/expertize_11zon.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import Hexgon from "Assets/HomePage/banner/hexagon.png";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { HiClipboardList } from "react-icons/hi";
import { SiCashapp } from "react-icons/si";
function Integratedwellness() {
    const [Tab, setActiveTab] = useState(1);
    return (
        <Layout>
            <div className={IntegratedStyles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={IntegratedStyles["hexagon"]}
                    />
                    <h3>Who we Are</h3>
                </header>
                <section className={IntegratedStyles["section_1"]}>
                    <article
                        style={{
                            gridColumn: "1/-1",
                            display: "grid",
                            textAlign: "center",
                            padding: "0.5rem",
                        }}>
                        {/* <h3>Who We Are</h3> */}
                        <p style={{ lineHeight: "1.4rem" }}>
                            At Digital Natives, we specialize in providing
                            advanced digital solutions tailored to your business
                            needs. Our services range from cloud computing and
                            data analytics to digital marketing and automation.
                            By integrating the latest technologies, we help you
                            streamline operations, enhance efficiency, and drive
                            growth, ensuring that your business remains
                            competitive in the ever-evolving digital landscape.
                        </p>
                    </article>
                    {/* <img src={staffing_1} alt="" /> */}
                </section>

                <section className={IntegratedStyles["section_1"]}>
                    <img src={staffing_3} alt="" />
                    <article>
                        <h3>Our Mission</h3>
                        <p>
                            Our mission is to empower organizations with the
                            insights and strategies they need to excel in a
                            competitive market. We are committed to providing
                            innovative solutions that are grounded in
                            data-driven analysis and practical experience. By
                            focusing on both immediate needs and long-term
                            goals, we help our clients achieve sustainable
                            growth and operational excellence.
                        </p>
                    </article>
                </section>

                <section className={IntegratedStyles["section_1"]}>
                    <article>
                        <h3>Our Expertise</h3>
                        <p>
                            At Maximum cloud Solutions, our diverse team of
                            experts provides a comprehensive range of consulting
                            services designed to drive your business forward. We
                            specialize in Strategic Planning, crafting effective
                            strategies that align with your business goals and
                            market dynamics to ensure long-term success. Our
                            focus on Operational Efficiency helps streamline
                            processes, enhance productivity, and reduce costs,
                            maximizing your operational potential. With our
                            expertise in Financial Management, we deliver
                            insightful solutions to optimize resource allocation
                            and boost profitability. Our Marketing and Branding
                            services are tailored to develop impactful
                            strategies that build and strengthen your brand
                            presence, capturing and engaging your target
                            audience. Additionally, our Technology Integration
                            expertise ensures the implementation of cutting-edge
                            solutions to drive innovation and enhance
                            operational efficiency. Together, these services are
                            designed to address your unique challenges and
                            propel your business toward greater achievements.
                        </p>
                    </article>
                    <img src={staffing_4} alt="" />
                </section>

                <div className={IntegratedStyles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt />{" "}
                        <span>(+1) 4083389367</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Integratedwellness;
