import React from "react";
import Styles from "./Styles.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/manu_02_11zon.jpg";
import Imag2 from "Assets/man_03_11zon.jpg";
import Imag3 from "Assets/manu_04_11zon.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import aboutimg_01 from "Assets/manu_01.jpg";
import aboutimg_02 from "Assets/About/about-img2.jpg";
import aboutimg_03 from "Assets/About/about-img3.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "Our Engineering and Product Design services integrate advanced testing, simulation, and RPA to optimize product development. We combine mechanical engineering expertise with intelligent systems to drive innovation and efficiency. Our focus is on transforming concepts into cutting-edge, market-ready products.",
        heading: "Engineering and Product Design",
    },
    {
        image: Imag2,
        Content:
            "Our Vendor & Supply Chain Administration services automate supply chain processes using Blockchain for secure data management and integrate flexible vendor administration. We streamline operations with Lean and Six Sigma methodologies, enhance demand and inventory management with data-driven insights",
        heading: "Vendor & Supply Chain Administration",
    },
    {
        image: Imag3,
        Content:
            "Our Manufacturing & Enterprise services focus on smart manufacturing with MES, IoT, and data integration, and offer simulation and visualization for enhanced process management. We streamline plant floor operations and quality management, incorporate PLM and SLM, and provide operational intelligence for predictive maintenance.",
        heading: "Manufacturing & Enterprise",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>Manufacturing and Distrubution</h3>
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <img src={aboutimg_01} alt="" />
                    <div className={Styles["section_01_about"]}>
                        <h3></h3>
                        <p>
                            Encourage the growth of the revenue from design and
                            manufacturing. Organisations are facing more and
                            more hurdles in terms of time to market, cost to
                            market, and competition. As a result, there is a
                            growing need for assistance in enhancing
                            manufacturing and design revenue turnouts. Our
                            product design and engineering domain at Maximum
                            Cloud Solutions includes advanced testing,
                            simulation, RPA, mechanical engineering, intelligent
                            systems, and innovation. Artificial Intelligence,
                            cloud development, RF, database services, IoT and
                            M2M, mobile application development,
                            firmware/middleware programming, and cloud
                            development are among the areas in which Maximum
                            Cloud Solutions offers solutions. We offer
                            data-driven demand and inventory management, agile
                            vendor management, business process optimisation,
                            big data and analytics for SCM and vendor
                            management, and intelligent and integrated
                            logistics. We also provide automated supply chain
                            leveraging blockchain and data. In addition, we
                            provide PLM and SLM, operational intelligence and
                            management, plant floor process optimisation and
                            quality management, simulation and visualisation,
                            smart manufacturing, application modernisation,
                            security, safety, regulation, and risk management.
                            Supporting end-to-end worldwide PLM systems, where
                            we commission new implementations, migrations, and
                            updates, is one of Maximum cloud Solutions'
                            offerings. We have experience with teamcenter,
                            windchill, areas, eMatrix, eMatrix, SAP PLM,
                            smarteam, and agile among other technologies.
                        </p>
                    </div>
                </div>
                <Service ServiceData={ServiceData} />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
