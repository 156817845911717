import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/AI/computer.jpg";
import Imag2 from "Assets/AI/intelligence.jpg";
import Imag3 from "Assets/AI/language.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import aboutimg_01 from "Assets/About/about-img1.jpg";
import aboutimg_02 from "Assets/About/about-img2.jpg";
import aboutimg_03 from "Assets/About/about-img3.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag2,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag3,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>AI / ML</h3>
                    <p>Solutions /AI</p>
                </header>
                <div className={Styles["section_1_wrapper"]}>
                    <section className={Styles["section_01"]}>
                        <div className={Styles["section_01_row"]}>
                            <img src={aboutimg_01} alt="image_1" />
                            <img src={aboutimg_02} alt="image_2" />
                        </div>
                        <div className={Styles["section_01_row"]}>
                            <p className={Styles["section_01_content"]}>
                                Years of Experience - <br />
                                Economical Solution
                            </p>
                            <img src={aboutimg_03} alt="image_2" />
                        </div>
                    </section>
                    <div className={Styles["section_01_about"]}>
                        <h3>
                            <span>Welcome To</span>
                            <br />
                            Maximum Cloud Solutions
                        </h3>
                        <p>
                            An organization steeped in professional competence,
                            Fortuelife Group is a name synonymous with Growth.
                            We are your gateway to a harmonious and holistic
                            lifestyle aiding you to have a balanced and peaceful
                            existence.
                            <br />
                            <br />
                            During the onset of the Industrial Revolution,
                            Fortuelife was established in India with a focus on
                            the strength of people and products. Through
                            integrated wellness, custom-integrated vacation
                            services, state-of-the-art infra-space properties,
                            and cutting-edge integrated fashion, our business is
                            committed to enhancing your well-being in all
                            realms.
                            <br />
                            <br />
                            We are innovators in health and wellness as our
                            experienced team is always creating high-quality
                            products by investing time in research that fosters
                            emotional, mental, and physical well-being. We think
                            that achieving genuine contentment requires a
                            journey, and we are here to help you along the way.
                            <br />
                            <br />
                            The guiding principles of Fortuelife emphasize
                            global development, providing opportunities that
                            uplift a society, recognizing individual
                            contributions, and giving back to the community. By
                            promoting financial independence and a sense of
                            community, the company hopes to spread positivity
                            throughout the world.
                        </p>
                    </div>
                </div>
                <Service ServiceData={ServiceData} />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
