import IntegratedStyles from "./Ai.module.css";
import Layout from "Components/Layouts/Common/common";
import { useState } from "react";
import about from "Assets/About/people.jpg";
import Intellectual from "Assets/About/intellectual12.png";
import Yoga from "Assets/About/yoga_01.jpg";
import staff5 from "Assets/staffing_5.jpg";
import staffing_1 from "Assets/native_1_11zon.jpg";
import staffing_3 from "Assets/native_2_11zon.jpg";
import staffing_4 from "Assets/native_3_11zon.jpg";
import { FaPhoneAlt } from "react-icons/fa";

import Hexgon from "Assets/HomePage/banner/hexagon.png";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { HiClipboardList } from "react-icons/hi";
import { SiCashapp } from "react-icons/si";
function Integratedwellness() {
    const [Tab, setActiveTab] = useState(1);
    return (
        <Layout>
            <div className={IntegratedStyles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={IntegratedStyles["hexagon"]}
                    />
                    <h3>Digital Natives</h3>

                    {/* <p>Solutions /Digital Transformation</p> */}
                </header>
                <section className={IntegratedStyles["section_1"]}>
                    <article>
                        <h3>Innovate with Cutting-Edge Digital Solutions</h3>
                        <p>
                            At Digital Natives, we specialize in providing
                            advanced digital solutions tailored to your business
                            needs. Our services range from cloud computing and
                            data analytics to digital marketing and automation.
                            By integrating the latest technologies, we help you
                            streamline operations, enhance efficiency, and drive
                            growth, ensuring that your business remains
                            competitive in the ever-evolving digital landscape.
                        </p>
                    </article>
                    <img src={staffing_1} alt="" />
                </section>

                <section className={IntegratedStyles["section_1"]}>
                    <img src={staffing_3} alt="" />
                    <article>
                        <h3>
                            Seamless Technology Integration and Expert Support
                        </h3>
                        <p>
                            We focus on seamless integration of innovative
                            technologies into your existing systems, ensuring
                            minimal disruption and maximum benefit. Our
                            experienced consultants offer expert guidance
                            throughout your digital transformation journey, from
                            initial planning to implementation and beyond. We
                            provide ongoing support to address any challenges
                            and optimize your digital strategies for sustained
                            success
                        </p>
                    </article>
                </section>

                <section className={IntegratedStyles["section_1"]}>
                    <article>
                        <h3>Future-Ready Solutions for a Competitive Edge</h3>
                        <p>
                            Prepare your business for future challenges with our
                            forward-thinking solutions. We leverage emerging
                            technologies and industry trends to keep you ahead
                            of the curve, offering customized strategies that
                            align with your goals. With our commitment to
                            innovation and expertise, Digital Natives empowers
                            you to unlock new opportunities and achieve
                            unparalleled success in the digital era.
                        </p>
                    </article>
                    <img src={staffing_4} alt="" />
                </section>

                <div className={IntegratedStyles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt />{" "}
                        <span>(+1) 4083389367</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Integratedwellness;
