import React, { useState } from "react";
import Layout from "Components/Layouts/Common/common";
import ContactClasses from "./Contact.module.css";
import { useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
// IMPORT NavLink
// import { Inputs } from "./Types";

function Contactus() {
    const Navigate = useNavigate();
    const [check, setCheck] = useState(false);
    return (
        <Layout>
            <div className={ContactClasses["Container"]}>
                <header>
                    <h3>Get in Touch with US</h3>
                </header>
                <div className={ContactClasses["form"]}>
                    <h5>We'd Love to Hear From You</h5>
                    <h3>Share your queries, suggestions, or just say hello!</h3>
                    <form>
                        <div className={ContactClasses["form-control"]}>
                            <input type="text" placeholder="First Name" />
                        </div>
                        <div className={ContactClasses["form-control"]}>
                            <input type="text" placeholder="Last Name" />
                        </div>
                        <div className={ContactClasses["form-control"]}>
                            <input type="text" placeholder="Your Email" />
                        </div>
                        <div className={ContactClasses["form-control"]}>
                            <input type="number" placeholder="Phone" />
                        </div>

                        <div
                            className={`${ContactClasses["form-control"]} ${ContactClasses["form-control-textarea"]}`}>
                            <textarea cols={30} rows={10}></textarea>
                        </div>
                        <div
                            className={ContactClasses["Checkbox"]}
                            style={{ gridColumn: "1/-1" }}
                            onClick={() => setCheck((prev) => !prev)}>
                            <input type="checkbox" checked={check} />
                            <label htmlFor="">
                                By opting in for text messages, you agree to
                                receive messages from Maximumcloudsolutions at
                                the number provided. Message frequency varies.
                                Msg & data rates may apply. View our{" "}
                                {
                                    <NavLink to={"/privacy-policy"}>
                                        Privacy Policy
                                    </NavLink>
                                }{" "}
                                for more information
                            </label>
                        </div>
                        <button
                            disabled={!check}
                            onClick={(e) => e.preventDefault()}>
                            Send Message
                        </button>
                    </form>
                </div>
                <div className={ContactClasses["map-container"]}>
                    {/* <Map /> */}
                </div>
            </div>
        </Layout>
    );
}

export default Contactus;
