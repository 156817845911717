import React from "react";
import Styles from "./Ai.module.css";
import robot1 from "Assets/AI/robot-2.jpg";
import Layout from "Components/Layouts/Common/common";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import Service from "Components/Home/Services/Service";
import Imag1 from "Assets/AI/computer.jpg";
import Imag2 from "Assets/AI/intelligence.jpg";
import Imag3 from "Assets/AI/language.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import Blog from "Components/Home/Blogs/Blogs";

import aboutimg_01 from "Assets/About/about-img1.jpg";
import aboutimg_02 from "Assets/About/about-img2.jpg";
import aboutimg_03 from "Assets/About/about-img3.jpg";
const ServiceData = [
    {
        image: Imag1,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag2,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
    {
        image: Imag3,
        Content:
            "Improving health and well-being through our therapeutic products. Our comprehensive approach and research focus on products that deliver strong clinical performance.",
        heading: "Integrated Wellness",
    },
];
function AI() {
    return (
        <Layout>
            <div className={Styles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={Styles["hexagon"]}
                    />
                    <h3>News Room</h3>
                </header>
                <Blog />
                <div className={Styles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt /> <span>(123) 456789</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default AI;
