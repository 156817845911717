import React from "react";
import OfferClasses from "../../../Components/Home/Offer/Offer.module.css";
import { BsFillSuitcaseLgFill } from "react-icons/bs";
import { HiClipboardList } from "react-icons/hi";
import { SiCashapp } from "react-icons/si";
import { TbCopy } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";

function Offer() {
    let Cards = [
        {
            Title: "knowledge focused",
            Content:
                "We've been in the industry for a number of years. We can provide you a new perspective by acting as an outside set of eyes. Your staff can miss crucial information because they are too busy concentrating on a lot of other things. We'll conduct a fair, unbiased, and objective analysis of your company and provide you with frank input on what needs to be altered.",
            Icon: <BsFillSuitcaseLgFill color="#141763" />,
        },
        {
            Title: "Human-centered solutions ",
            Content:
                "The knowledge needed for business expansion isn't always present in a company's human resources department. We are well-versed in market and economic trends, having worked with a wide range of clients in the past. We can assist you in outwitting your rivals if you have these abilities.",
            Icon: <HiClipboardList color="#141763" />,
        },
        {
            Title: "Business result",
            Content:
                "We have the academic and theoretical know-how to integrate it with real-world components. Why not outsource the work at a lower cost to your HR department instead of lavishing enormous amounts of resources on educating them?",
            Icon: <SiCashapp color="#141763" />,
        },
    ];
    return (
        <div className={OfferClasses["Container"]}>
            <header>
                <h3 style={{ color: "black" }}>What we provide</h3>
            </header>
            <div
                className={OfferClasses["Cards"]}
                style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <div className={OfferClasses["Card"]}>
                    <div>{Cards[0].Icon}</div>
                    <h3>{Cards[0].Title}</h3>

                    <ul>
                        <li>Everything intelligent</li>
                        <li>Quickened procedures</li>
                        <li>liAgile and robust business</li>
                        <li>quicker and wiser decision-making</li>
                        <li>prompt delivery</li>
                        <li> Integration of the supply chain</li>
                        <li>Automated processes</li>
                    </ul>
                </div>
                <div className={OfferClasses["Card"]}>
                    <div>{Cards[1].Icon}</div>
                    <h3>{Cards[1].Title}</h3>

                    <ul>
                        <li>Immersion-based activities</li>
                        <li>cooperative setting</li>
                        <li>Culture of innovation</li>
                        <li>Durability</li>
                        <li>contentment in one's work</li>
                        <li> bringing in and keeping elite talent</li>
                        {/* <li>Automated processes</li> */}
                    </ul>
                </div>
                <div className={OfferClasses["Card"]}>
                    <div>{Cards[2].Icon}</div>
                    <h3>{Cards[2].Title}</h3>

                    <ul>
                        <li>Lowering of costs</li>
                        <li>Simplifying</li>
                        <li>Durability</li>
                        <li>Quickness Automation</li>
                        <li>AI and analytical</li>
                        {/* <li>Automated processes</li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Offer;
