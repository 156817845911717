import React from "react";
import Layout from "Components/Layouts/Common/common";
import LayoutClasses from "./policy.module.css";
function policy() {
    return (
        <Layout>
            <div className={LayoutClasses["Container"]}>
                <h3>Privacy policy</h3>
                <p>
                    Maximum Cloud Solutions LLC, hereinafter referred as
                    ‘Maximum Cloud Solutions’, ‘we’, ‘us’ or ‘our’ is committed
                    to respect your privacy.
                </p>
                <p>
                    This Privacy Policy highlights our privacy practices
                    regarding “Personal Information” that we collect and store
                    about you through this website,
                    https://maximumcloudsolutions.com/ (“Site”), and also for
                    those Personal Information that you provide us while
                    visiting and navigating the Site. This Privacy Policy
                    applies to our use of any and all data collected by us or
                    provided by you in relation to the use of the site.
                </p>
                <p>
                    Personal Information (Collection, Use and Process) ‘Personal
                    Information’ is defined as “any data which relates to an
                    individual who may be identified from that data, or from a
                    combination of a set of data, and other information which is
                    in possession of Maximum Cloud Solutions.
                </p>
                <h4>
                    Maximum Cloud Solutions collects the following categories of
                    Personal Information:
                </h4>
                <ul>
                    <li>
                        Information that you provide by visiting or navigating
                        the Site, including information you provide when you
                        register in the Site e.g. name, email address,
                        designation, company, country, and telephone number
                        etc.; Information about your computer and about your
                        visits to and use of the Site, such as your Internet
                        Protocol (IP) address, demographics, your computer’s
                        operating system, and browser type and information
                        collected via cookies;
                    </li>
                    <li>
                        Cookie related details. Please refer to our policy
                        regarding Cookies and Website Analytics
                    </li>
                </ul>
                <h4>Applicable Data Protection Laws</h4>
                <p>
                    For residents (data subjects) of the European Union, when we
                    collect and use personal data (information) about you, we
                    may be subject to the General Data Protection Regulation
                    (‘GDPR’) of the European Union and be responsible as a ‘data
                    controller’ for such personal data. For residents of other
                    countries, when we collect and use personal information
                    about you, we may be subject to the applicable Data
                    Protection Laws in those countries. Use of Personal
                    Information The purposes for which we use your Personal
                    Information are the following:
                </p>
                <ul>
                    <li>
                        To improve usability, troubleshooting and maintenance;
                    </li>
                    <li>
                        To know which areas of the site that you visited and its
                        frequency;
                    </li>
                    <li>To create your user credentials;</li>
                    <li>To identify you once you register in our site;</li>
                    <li>
                        To contact you and respond to your questions or
                        requests; and
                    </li>
                    <li>
                        To provide access to desirable content based on your
                        preferences.
                    </li>
                    <li>
                        To use for any other purpose related to your use of our
                        site. Legal basis for processing
                    </li>
                </ul>
                <p>
                    With your consent: We process your Personal Information with
                    your consent for the below purpose(s). Where we process
                    Personal Information based on consent, your consent is
                    revocable at any time.
                </p>
                <p>
                    We might use your personal data for the purpose of sending
                    you our newsletter, any other information, including
                    marketing information and surveys.
                </p>
                <p>
                    For the performance of a contract: We process your Personal
                    Information for the performance of a contract in the
                    following circumstances:
                </p>
                To provide better usability, troubleshooting and site
                maintenance; To create your user ID.
                <p>
                    For our legitimate interests: We process your Personal
                    Information when it is necessary for the purposes of a
                    legitimate interest pursued by us or a third party (when
                    these interests are not overridden by your data protection
                    rights).
                </p>
                <h4>This applies in the following circumstances:</h4>
                <ul>
                    <li>
                        To identify you once you register on our website; and
                    </li>
                    <li>
                        To contact you and respond to your questions or
                        requests;
                    </li>
                    <li>
                        To understand which parts of the website are visited and
                        how frequently; and To provide access to desirable
                        content based on your preferences.
                    </li>
                    <li>
                        If you choose not to provide your Personal Information
                        that is mandatory to process your request, we may not be
                        able to provide the corresponding service.
                    </li>
                    <li>
                        No mobile information will be sold or shared with third
                        parties for promotional or marketing purposes
                    </li>
                </ul>
                <p>We Dont share any information with 3rd parties</p>
                {/* <p>
                    We may share your Personal Information within:
                    <br />
                    Maximum Cloud Solutions or with any of its subsidiaries.
                    Service vendors
                    <br />
                    Contractors
                    <br />
                    Your rights
                    <br />
                </p> */}
                <p>
                    Request access to your personal data: This enables you to
                    receive a copy of the personal data we hold about you.
                    Request correction of the personal data that we hold about
                    you: This enables you to ask us to have any incomplete or
                    inaccurate information we hold about you corrected.
                </p>
                <p>
                    Request erasure of your personal data: This enables you to
                    ask us, in certain situations, to delete or remove personal
                    data where there is no good reason for us continuing to
                    process it. You also have the right to ask us to delete or
                    remove your personal data where you have exercised your
                    right to object to processing (see below).
                </p>
                <p>
                    Object to processing of your personal data: We are relying
                    on a legitimate interest (or those of a third party) and
                    there is something about your particular situation which
                    makes you want to object to processing on this ground. You
                    also have the right to object where we are processing your
                    personal data.
                </p>
                <p>
                    Request the restriction of processing of your personal data:
                    This enables you to ask us, in certain circumstances, to
                    suspend the processing of personal data about you, for
                    example if you want us to establish its accuracy or the
                    reason for processing it.
                </p>
                <p>
                    Data portability: This enables you to ask us, in certain
                    circumstances, to provide you with the personal data you
                    have provided to us in a structured, commonly used and
                    machine-readable format or to transmit the personal data
                    that you have provided to us to another party. Automated
                    decision-making: The right not to be subject to a decision
                    based solely on automated processing that produces legal
                    effects concerning you or similarly significantly affects
                    you.
                </p>
                <h4>Data security</h4>
                <p>
                    We have taken all reasonable processes and procedures
                    including administrative, physical security and technical
                    controls to protect your Personal Information.
                </p>
                <h4>Data retention</h4>
                <p>
                    Personal Information will not be retained for a period more
                    than necessary to fulfill the purposes outlined in this
                    privacy policy, unless a longer retention period is required
                    by law or for directly related legitimate business purposes.
                </p>
                <h4>Links to third party websites</h4>
                <p>
                    Maximum Cloud Solutions provides links to third-party
                    websites and services. However, Maximum Cloud Solutions is
                    not responsible for the privacy statements, practices, or
                    the contents of such third-party websites.
                </p>
                <h4>Children’s privacy</h4>
                <p>
                    Another part of our priority is adding protection to
                    children while using the internet. Maximum Cloud Solutions
                    does not knowingly collect any Personal Identifiable
                    Information from children under the age of 18. If you think
                    that your child provided this kind of information on our
                    website, we strongly encourage you to contact us immediately
                    and we will do our best efforts to promptly remove such
                    information from our records.
                </p>
                <h4>Our contact details</h4>
                <p>
                    If you have any questions regarding our privacy practices or
                    this privacy statement, please contact us at
                    contact@maximumcloudsolutions.com
                </p>
                <h4>Amendments to this Policy</h4>
                <p>
                    We may change the data privacy practices and update this
                    privacy policy as and when required, and the changes will be
                    made available on the site. But our commitment to protect
                    the privacy of website users will continue to remai
                </p>
            </div>
        </Layout>
    );
}

export default policy;
