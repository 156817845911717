import IntegratedStyles from "./Styles.module.css";
import Layout from "Components/Layouts/Common/common";

import three from "Assets/Digital_transformation_3.jpg";
import Intellectual from "Assets/Digital_transformation_2.jpg";
import Yoga from "Assets/Digital_transformation_1.jpg";
import Social from "Assets/data-analysis_01.jpg";
import Hexgon from "Assets/HomePage/banner/hexagon.png";
import four from "Assets/data_transformation_4.jpg";
import { FaPhoneAlt } from "react-icons/fa";
function Integratedwellness() {
    return (
        <Layout>
            <div className={IntegratedStyles["Container"]}>
                <header>
                    <img
                        src={Hexgon}
                        alt="Hexgon"
                        className={IntegratedStyles["hexagon"]}
                    />
                    <h3>DATA ANALYSIS</h3>
                </header>
                <section className={IntegratedStyles["section_1"]}>
                    <article>
                        <h3>Unlock the value in your Data</h3>
                        <br />
                        <p>
                            Maximum Cloud Solutions is your reliable ally in
                            transforming into a data-driven organization. We
                            craft and implement strategic roadmaps that
                            integrate cutting-edge technologies and solutions,
                            ensuring clean data and sophisticated analytics
                            across your entire enterprise.
                        </p>
                        <br />
                        <p>
                            Our extensive network of partner capabilities
                            enables us to provide validated industry solutions
                            from leading independent software providers. We also
                            assist clients in navigating the cultural and
                            organizational shifts necessary to embrace this
                            service-focused approach fully.
                        </p>
                    </article>
                    <img src={Yoga} alt="" />
                </section>
                <section className={IntegratedStyles["section_2"]}>
                    <header>
                        <h3>
                            Data Analytics Solutions: Our Four Key Foundations
                        </h3>
                    </header>
                    <div className={IntegratedStyles["card"]}>
                        <img src={Intellectual} alt="Intellectual" />
                        <div className={IntegratedStyles["Content"]}>
                            <h3>Foundation 1 : Aggregation and refinement.</h3>
                            <p>
                                Centralizing data ensures everyone has access to
                                the same accurate information. This makes
                                quality checks consistent and connects everyone
                                with the data they need. Departments can trust
                                this reliable data for making decisions and
                                using advanced technologies like AI and
                                automation. As a result, businesses can depend
                                on the insights generated from this data.
                            </p>
                            <p>
                                Centralizing information guarantees that
                                everybody has get to to the same, precise data.
                                This consistency rearranges quality checks and
                                interfaces all group individuals with the
                                information they require. With dependable
                                information, divisions can make well-informed
                                choices and successfully utilize progressed
                                innovations like AI and mechanization. Steady
                                information get to too improves proficiency over
                                the organization. As a result, businesses can
                                unquestionably believe the bits of knowledge
                                produced from this centralized information. This
                                approach cultivates more prominent
                                decision-making and operational viability.
                            </p>
                        </div>
                    </div>
                    <div className={IntegratedStyles["card"]}>
                        <div className={IntegratedStyles["Content"]}>
                            <h3>Foundation 2 : Access to data for everyone</h3>
                            <p>
                                Maximum Cloud Solutions provides business data
                                solutions that ensure data is accessible to
                                everyone, not just IT professionals. Business
                                users can harness this data for improved
                                decision-making and drive innovation with
                                actionable insights. Meanwhile, IT teams can
                                focus on more complex projects and pipelines.
                            </p>
                            <p>
                                With dependable data, companies can deploy
                                solutions more efficiently and accelerate their
                                time to market. Welcome to the era of genuinely
                                data-driven business.
                            </p>
                        </div>
                        <img src={three} alt="Intellectual" />
                    </div>
                </section>
                <section className={IntegratedStyles["section_2"]}>
                    <div className={IntegratedStyles["card"]}>
                        <img src={four} alt="Intellectual" />
                        <div className={IntegratedStyles["Content"]}>
                            <h3>
                                Foundation 3 : Data visualization and
                                self-service access to insights.
                            </h3>
                            <p>
                                All users may now access the data they require
                                without having to wait months for IT assistance
                                thanks to self-services data analytics
                                solutions. Well-curated and high-quality data
                                sets facilitate swift story telling and point
                                making, enabling companies to make speedy
                                pivots.
                            </p>
                        </div>
                    </div>
                    <div className={IntegratedStyles["card"]}>
                        <div className={IntegratedStyles["Content"]}>
                            <h3>
                                Foundation 4 : sophisticated analytics across
                                the board
                            </h3>
                            <p>
                                Advanced analytics methods and data management
                                tools are offered by Maximum Cloud Solutions for
                                improved decision-making and enterprise-wide
                                insights that reveal value. It fully governs the
                                monetisation of the company's confidential data,
                                which is frequently extremely valuable.
                            </p>
                        </div>
                        <img src={Social} alt="Intellectual" />
                    </div>
                </section>
                <div className={IntegratedStyles["Callback"]}>
                    <h3>Lets work Together</h3>
                    <h1>
                        We Love to Listen to Your <br /> Requirements
                    </h1>
                    <button>Estimate Project</button>

                    <p>
                        Or Call us now <FaPhoneAlt />{" "}
                        <span>(+1) 4083389367</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Integratedwellness;
